import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import StatisticWidget from "components/shared-components/StatisticWidget";
import { useDispatch } from "react-redux";
import { fetchAllCategory } from "redux/features/category";
import { fetchAllContent } from "redux/features/content";
import { fetchAllSubcategory } from "redux/features/subcategory";

export const DefaultDashboard = () => {
  const dispatch = useDispatch();
  const [categoryCount, setCategoryCount] = useState(0);
  const [contentCount, setContentCount] = useState(0);
  const [subcategoryCount, setSubcategoryCount] = useState(0);

  const getAllCategory = async () => {
    try {
      const response = await dispatch(fetchAllCategory()).unwrap();
      setCategoryCount(response.meta.page.total_data);
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const getAllSubcategory = async () => {
    try {
      const response = await dispatch(fetchAllSubcategory()).unwrap();
      setSubcategoryCount(response.meta.totalData);
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const getAllContent = async (params) => {
    try {
      const response = await dispatch(fetchAllContent(params)).unwrap();
      setContentCount(response.meta.page.total_data);
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  useEffect(() => {
    const filters = {
      page: 1,
      limit: 10,
      sort: "created_at",
    };

    getAllCategory();
    getAllContent(filters);
    getAllSubcategory();
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>
            Jika menemukan bug ataupun error pada aplikasi ini bisa langsung
          </p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Semua Konten`}
                value={contentCount}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Semua Kategori`}
                value={categoryCount}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Semua Subkategori`}
                value={subcategoryCount}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(DefaultDashboard);
