import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { addStatic, fetchOneStatic, updateStatic } from "redux/features/static";
import TextArea from "antd/lib/input/TextArea";

export const DETAIL_STATIC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();
  const [data, setData] = useState({});

  const onFinish = (values) => {
    if (location?.state?.id) {
      updateData({
        ...values,
        id: location.state.id,
      });
    } else {
      createData(values);
    }
  };

  const createData = async (values) => {
    try {
      await dispatch(addStatic(values)).unwrap();
      history.push("/app/statics");
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const updateData = async (values) => {
    try {
      await dispatch(updateStatic(values)).unwrap();
      history.push("/app/statics");
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneStatic(id)).unwrap();
      form.setFieldsValue({
        ...data.data,
      });

      setData({ ...data.data });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddLabel = () => {
    const labelCount = Object.keys(data).filter((item) =>
      item.startsWith("label")
    ).length;

    setData((prev) => ({ ...prev, [`label${labelCount + 1}`]: "" }));
  };

  useEffect(() => {
    if (location?.state?.id) {
      getDataById(location?.state?.id);
    }
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Add/Update Static</h2>
          <p>Please fill page static data</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Name"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input your !",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              {Object.keys(data)
                .filter((item) => item.startsWith("label"))
                .map((item, index) => (
                  <>
                    <Form.Item label={item} name={item}>
                      <TextArea rows={5} />
                    </Form.Item>
                  </>
                ))}

              {/* <Button
                type="primary"
                style={{ marginBottom: 12 }}
                onClick={handleAddLabel}
              >
                Add Label
              </Button> */}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DETAIL_STATIC;
