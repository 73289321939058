import { configureStore } from '@reduxjs/toolkit'
import themeReducer from 'redux/features/theme'
import authReducer from 'redux/features/auth'
import userReducer from 'redux/features/user'
import contentReducer from 'redux/features/content'
import messagesReducer from 'redux/features/messages'
import categoryReducer from 'redux/features/category'
import categorizationReducer from 'redux/features/categorization'

const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    content:contentReducer,
    messages:messagesReducer,
    category:categoryReducer,
    categorization:categorizationReducer,
    user: userReducer
  }
});

export default store;

