import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import URLS from "redux/urls";
import request from "redux/utils/request";

export const fetchAllStatic = createAsyncThunk(
  "Static/fetchAllStatic",
  async (_, { rejectWithValue }) => {
    try {
      const response = await request("get", URLS.STATIC);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addStatic = createAsyncThunk(
  "Static/addStatic",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await request("post", URLS.STATIC, payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateStatic = createAsyncThunk(
  "Static/updateStatic",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await request(
        "patch",
        `${URLS.STATIC}/${payload.id}`,
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const fetchOneStatic = createAsyncThunk(
  "Static/fetchOneStatic",
  async (id, { rejectWithValue }) => {
    try {
      const response = await request("get", `${URLS.STATIC}/${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteStatic = createAsyncThunk(
  "Static/deleteStatic",
  async (id, { rejectWithValue }) => {
    try {
      const response = await request("delete", `${URLS.STATIC}/${id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: {
    query: false,
    mutation: false,
  },
  filter: {
    q: "",
  },
  list: [],
  selected: {},
  selectedRows: [],
};

const loadingReducer = (fieldName, status) => (state) => {
  state.loading[fieldName] = status;
};

const startLoadingQuery = loadingReducer("query", true);
const stopLoadingQuery = loadingReducer("query", false);
const startLoadingMutation = loadingReducer("mutation", true);
const stopLoadingMutation = loadingReducer("mutation", false);

export const StaticSlice = createSlice({
  name: "Static",
  initialState,
  reducers: {
    setAppliedSearchText: (state, action) => {
      state.filter.q = action.payload;
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllStatic.pending, startLoadingQuery)
      .addCase(fetchAllStatic.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading.query = false;
      })
      .addCase(fetchAllStatic.rejected, stopLoadingQuery);
    builder
      .addCase(fetchOneStatic.pending, startLoadingQuery)
      .addCase(fetchOneStatic.rejected, stopLoadingQuery)
      .addCase(fetchOneStatic.fulfilled, (state, action) => {
        state.loading.query = false;
        state.selected = action.payload;
      });
    builder
      .addCase(updateStatic.pending, startLoadingQuery)
      .addCase(updateStatic.rejected, stopLoadingQuery)
      .addCase(updateStatic.fulfilled, (state, action) => {
        state.loading.query = false;
        state.selected = action.payload;
      });
    builder
      .addCase(deleteStatic.pending, startLoadingMutation)
      .addCase(deleteStatic.fulfilled, stopLoadingMutation)
      .addCase(deleteStatic.rejected, stopLoadingMutation);
  },
});

export const { setSelectedRows, setAppliedSearchText } = StaticSlice.actions;

export default StaticSlice.reducer;
