const URLS = Object.freeze({
  LOGIN: `/api/v1/auth/login`,
  REGISTER: `api/v1/auth/register`,
  STORE_GOOGLE_ACCOUNT: `/user/auth-google`,
  ACTIVATION: `/send-activation`,
  RESET_PASSWORD: `user/reset-password`,
  RESEND_ACTIVATION: `user/resend-activation`,
  PROFILE: `/users`,
  USER: "/users",
  CONTENT: "/content",
  CATEGORY: "/category",
  MESSAGE: "/messages",
  CATEGORIZATION: "/categorization",
  TAG: "/tag",
  SUBCATEGORY: "/sub_categories",
  STATIC: "/static",
});

export default URLS;
