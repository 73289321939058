import { strings } from "res";
import { DashboardIcon, OrderIcon, ReportIcon } from "../assets/svg/icon";
import {
  UserOutlined,
  TagOutlined,
  BranchesOutlined,
  FileOutlined,
  MessageFilled
} from "@ant-design/icons";

const dashBoardNavTree = [
  {
    key: "Home",
    path: strings.navigation.path.dashboard,
    title: "Home",
    breadcrumb: false,
    submenu: [
      {
        key: "Dashboard",
        path: strings.navigation.path.dashboard,
        title: "Dashboard",
        icon: DashboardIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Statics",
        path: strings.navigation.path.static_list,
        title: "Web Statics",
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Messages",
        path: strings.navigation.path.messages,
        title: "Messages",
        icon: MessageFilled,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Contents",
        path: strings.navigation.path.contents,
        title: "Contents",
        icon: ReportIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Categories",
        path: strings.navigation.path.categories,
        title: "Categories",
        icon: OrderIcon,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Subcategories",
        path: strings.navigation.path.subcategories,
        title: "Subcategories",
        icon: BranchesOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Tags",
        path: strings.navigation.path.tags,
        title: "Tags",
        icon: TagOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Users",
        path: strings.navigation.path.users,
        title: "Users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      }
    ],
  },
];

// const ControlTree = [{
//   key: "Pengaturan",
//   path: strings.navigation.path.settings,
//   title: "Pengaturan",
//   breadcrumb: false,
//   submenu: [
//     {
//       key: "Pengaturan",
//       path: strings.navigation.path.settings,
//       title: "Pengaturan",
//       icon: SettingIcon,
//       breadcrumb: false,
//       submenu: []
//     }
//   ]
// }]

const navigationConfig = [
  ...dashBoardNavTree,
  // ...ControlTree,
];

export default navigationConfig;
